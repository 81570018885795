<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <Step_3
              standalone
              :id="Number($route.params.id)"
              @on-update="onUpdateData"
              @on-hide-alert="msg.has = false"
            >
              <template #alert>
                <b-alert
                  :variant="msg.type"
                  dismissible
                  class="alert-msg"
                  v-model="msg.has"
                  :show="msg.text"
                  >{{ msg.text }}</b-alert
                >
              </template>
            </Step_3>
          </div>
        </div>

        <div class="row">
          <div class="col-auto mx-auto text-center">
            <router-link to="/my-templates/" class="btn btn-warning mx-2 mb-2">
              {{ $t("btn.back") }}
            </router-link>
            <button
              :disabled="inprogress"
              @click.prevent="saveTemplateSettings"
              class="btn btn-primary mx-2 mb-2 mr-lg-0"
            >
              {{ $t("btn.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import Step_3 from "@/views/pages/wizard/step_3";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("my-template.edit"),
    };
  },
  components: {
    Layout,
    PageHeader,
    Step_3,
  },
  data() {
    return {
      title: "my-template.edit",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.my-templates",
          to: "/my-templates",
        },
        {
          tran: "my-template.edit",
          active: true,
        },
      ],
      template: "",
      metrics: "",
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
    };
  },
  methods: {
    onUpdateData(metrics, template) {
      // console.log("onUpdateData, metrics: ", metrics);
      // console.log("onUpdateData, template: ", template);
      this.metrics = metrics;
      this.template = template;
    },
    async saveTemplateSettings() {
      this.inprogress = true;

      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      const formData = new FormData();

      formData.append("_method", "PATCH");
      formData.append("name", this.template.name);
      formData.append("type", this.template.type);

      if (Number(this.template.type) === 1) {
        formData.append("templateable_id", 0);
      } else {
        formData.append("templateable_id", this.template.templateable_id);
      }

      this.metrics.map((m) => {
        formData.append("metrics[]", JSON.stringify(m));
      });

      // Display the key/value pairs ###debug
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + " :", pair[1]);
      // }

      try {
        await axios.post(
          this.$urls.URL_TEMPLATES + `/${this.template.id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        this.msg.has = true;
        this.msg.type = "success";
        this.msg.text = this.$t("template.msg-edit-success");
      } catch (error) {
        console.log("Error: ", error);

        this.msg.has = true;
        this.msg.type = "danger";
        this.msg.text =
          error.response?.data?.message || this.$t("template.msg-edit-error");
      } finally {
        this.$scrollToTop();
        this.inprogress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-msg {
  margin: 0 auto 1.5rem auto;
  max-width: 500px;
}
.btn {
  min-width: 180px;
}
</style>
